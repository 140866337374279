<template>
  <el-table
    class="tw_table"
    :max-height="height || 540"
    :data="items"
    :header-cell-class-name="sortBackground"
    :row-key="rowKey"
    :highlight-current-row="highlightCurrentRow"
    :row-class-name="rowClassName"
    :default-expand-all="defaultExpandAll"
    :expand-row-keys="internalExpandRowKeys"
    @header-click="sortChange"
    @current-change="handleCurrentChange"
    @selection-change="selectionChange"
    @row-click="rowClick"
    @row-dblclick="rowDbClick"
    @cell-click="cellClick"
    @cell-mouse-enter='cellMouseEnter'
    @cell-mouse-leave='cellMouseLeave'
    @expand-change="expandChange"
    @header-dragend="headerDragend"
    @row-contextmenu="onRowContextmenu"
    :border="false"
    ref="table"
    >

    <el-table-column
      v-if="selection"
      type="selection"
      :reserve-selection="true"
      :fixed="false"
      :resizable="false"
      width="70"
      align="center">
    </el-table-column>
    <el-table-column
       v-if="expand"
       type="expand"
       :fixed="false"
       :resizable="false"
       width="40"
       :render-header="renderExpandHeader"
    >
      <template slot-scope="props">
        <tw-process-table
          :schemas="processSchemas"
          :items="props.row.entityStatus"
          :processTable="true"
          :height="10000"
          :mode="mode"
          :flow="props.row"
          :flowUuid="props.row.flowUuid"
          :tradingFlowId="props.row.tradingFlowId"
          :editableTradingFlowIds="editableTradingFlowIds"
          @process-row-click="processRowClick"
          @process-row-dbclick="processRowDbClick"
        />
      </template>
    </el-table-column>
    <template v-for="schema in schemas">
      <el-table-column
        v-if="schema.dataType === 'LcPoolDetail'"
        :key="schema.key"
        type="expand"
        :fixed="schema.fixed"
        width="40"
        :render-header="renderExpandHeader"
      >
        <template slot-scope="props">
          <div class="lc_pool_detail" v-html="sanitize(props.row[schema.key])" />
        </template>
      </el-table-column>
      <el-table-column
        v-else-if="schema.dataType === 'Customer'"
        :key="schema.key"
        :prop="schema.key"
        :label="getLabel(schema.label)"
        :width="schema.width"
        :align="schema.align"
        :type="schema.dataType"
        :formatter="formatter"
        :fixed="schema.fixed"
        :render-header="renderHeader"
        :show-overflow-tooltip="!schema.hideTooltip"
      >
        <template slot-scope="props">
          <div style="opacity: 0;height: 0;min-width: calc(100% + 16px);">{{getCustomerLongName(props.row, schema.key)}}</div>
          <span class="customerName" style="display:flex;align-items:center" :content="getCustomerName(props.row, schema.key)"><figure class="avatar table"><img :src="getCustomerIcon(props.row, schema.key)"></figure></span>
        </template>
      </el-table-column>
      <el-table-column
        v-else-if="schema.dataType === 'User'"
        :key="schema.key"
        :prop="schema.key"
        :label="getLabel(schema.label)"
        :width="schema.width"
        :align="schema.align"
        :type="schema.dataType"
        :formatter="formatter"
        :fixed="schema.fixed"
        :render-header="renderHeader"
        :show-overflow-tooltip="!schema.hideTooltip"
      >
        <template slot-scope="props">
          <figure class="avatar table" style="display: inline-block;vertical-align: middle;"><img :src="getUserIcon(props.row, schema.key)"></figure><span style="display: inline;vertical-align: middle;">{{getUserName(props.row, schema.key)}}</span>
        </template>
      </el-table-column>
      <el-table-column
        v-else-if="schema.dataType === 'UserMail'"
        :key="schema.key"
        :prop="schema.key"
        :label="getLabel(schema.label)"
        :width="schema.width"
        :align="schema.align"
        :type="schema.dataType"
        :formatter="formatter"
        :fixed="schema.fixed"
        :render-header="renderHeader"
        :show-overflow-tooltip="!schema.hideTooltip"
      >
        <template slot-scope="props">
          <div style="opacity: 0;height: 0;min-width: calc(100% + 16px);">{{getUserName(props.row, schema.key)}}</div>
          <span class="customerName" style="display:flex;align-items:center" :content="getUserName(props.row, schema.key)"><figure class="avatar table"><img :src="getUserIcon(props.row, schema.key)"></figure></span>
        </template>
      </el-table-column>
      <el-table-column
        v-else-if="schema.dataType === 'Status'"
        :key="schema.key"
        :prop="schema.key"
        :label="getLabel(schema.label)"
        :width="schema.width"
        :align="schema.align"
        :type="schema.dataType"
        :formatter="formatter"
        :fixed="schema.fixed"
        :render-header="renderHeader"
        :resizable="false"
      >
        <template slot-scope="props">
          <tw-status-label :statusName="props.row[schema.key]" size='medium'/>
        </template>
      </el-table-column>
      <el-table-column
        v-else-if="schema.dataType === 'EventCancel'"
        :key="schema.key"
        :prop="schema.key"
        :label="getLabel(schema.label)"
        :width="schema.width"
        :align="schema.align"
        :type="schema.dataType"
        :formatter="formatter"
        :fixed="schema.fixed"
        :render-header="renderHeader"
        :resizable="false"
      >
        <template slot-scope="props">
          <tw-status-label v-if="props.row[schema.key] === PROCESS_CANCEL_FLG.ON" statusName="DELETE" />
        </template>
      </el-table-column>
      <el-table-column
        v-else-if="schema.dataType === 'DeleteFlag' || schema.dataType === 'DeleteFlagLine'"
        :key="schema.key"
        :prop="schema.key"
        :label="getLabel(schema.label)"
        :width="schema.width"
        :align="schema.align"
        :type="schema.dataType"
        :formatter="formatter"
        :fixed="schema.fixed"
        :render-header="renderHeader"
        :class-name="schema.dataType === 'DeleteFlagLine' ? 'left_line' : ''"
        :resizable="false"
      >
        <template slot-scope="props">
          <tw-status-label v-if="props.row[schema.key] === String(DELETE_FLG.DELETED) || props.row[schema.key] ===DELETE_FLG.DELETED" statusName="DELETE" />
        </template>
      </el-table-column>
      <el-table-column
        v-else-if="schema.dataType === 'AttachFile'"
        :key="schema.key"
        :prop="schema.key"
        :label="getLabel(schema.label)"
        :width="schema.width"
        :align="schema.align"
        :type="schema.dataType"
        :formatter="formatter"
        :fixed="schema.fixed"
        :render-header="renderHeader"
      >
        <template slot-scope="props">
          <figure v-if="props.row[schema.key]" class="attach_file"><img src="@/assets/images/icons/icon_attach_file.svg"></figure>
        </template>
      </el-table-column>
      <el-table-column
        v-else-if="schema.dataType === 'StatusBar'"
        :key="schema.key"
        :prop="schema.key"
        :label="getLabel(schema.label)"
        :width="schema.width"
        :align="schema.align"
        :type="schema.dataType"
        :formatter="formatter"
        :fixed="schema.fixed"
        :render-header="renderHeader"
        :resizable="false"
      >
        <template slot-scope="props">
          <tw-status-bar v-if="props.row[schema.key] || props.row[schema.key] === 0" :statusCode="props.row[schema.key]" :approval="props.row.processId ? getProgressApproval(props.row.processId) : true" />
        </template>
      </el-table-column>
      <el-table-column
        v-else-if="schema.dataType === 'StatusIcon'"
        :key="schema.key"
        :prop="schema.key"
        :label="getLabel(schema.label)"
        :width="schema.width"
        :align="schema.align"
        :type="schema.dataType"
        :formatter="formatter"
        :fixed="schema.fixed"
        :render-header="renderHeader"
        :resizable="false"
      >
        <template slot-scope="props">
          <tw-status-icon v-if="props.row[schema.key] || props.row[schema.key] === 0" :statusCode="props.row[schema.key]" :approval="props.row.processId ? getProgressApproval(props.row.processId) : true" />
        </template>
      </el-table-column>
      <el-table-column
        v-else-if="schema.dataType === 'EntityStatusIcon'"
        :key="schema.key"
        :prop="schema.key"
        :label="getLabel(schema.label)"
        :width="schema.width"
        :align="schema.align"
        :type="schema.dataType"
        :formatter="formatter"
        :fixed="schema.fixed"
        :render-header="schema.header === 'EntityIcon' ? undefined : renderHeader"
        :resizable="false"
      >
        <template v-if="schema.header === 'EntityIcon'" v-slot:header>
          <tw-entity-icon :entityName="schema.label" :popover="true" size="24" style="margin: 0 auto;box-sizing:content-box;padding-right: 4px" />
        </template>
        <template slot-scope="props">
          <tw-entity-status-icon v-if="props.row[schema.key] || props.row[schema.key] === 0" :statusCode="props.row[schema.key]" />
        </template>
      </el-table-column>
      <el-table-column
        v-else-if="schema.dataType === 'EntityIcon'"
        :key="schema.key"
        :prop="schema.key"
        :label="getLabel(schema.label)"
        :width="schema.width"
        :align="schema.align"
        :type="schema.dataType"
        :formatter="formatter"
        :fixed="schema.fixed"
        :render-header="renderHeader"
        :show-overflow-tooltip="!schema.hideTooltip"
        :resizable="false"
      >
        <template slot-scope="props">
          <!-- <div style="opacity: 0;height: 0;min-width: calc(100% + 16px);">{{getEntityNameByProcessId(props.row[schema.key])}}</div> -->
          <tw-entity-icon v-if="props.row[schema.key] && getParent(props.row[schema.key])" :popover="true" :entityName="getParent(props.row[schema.key])" />
        </template>
      </el-table-column>
      <el-table-column
        v-else-if="schema.dataType === 'EntityIcon2'"
        :key="schema.key"
        :prop="schema.key"
        :label="getLabel(schema.label)"
        :width="schema.width"
        :align="schema.align"
        :type="schema.dataType"
        :formatter="formatter"
        :fixed="schema.fixed"
        :render-header="renderHeader"
        :show-overflow-tooltip="!schema.hideTooltip"
        :resizable="false"
      >
        <template slot-scope="props">
          <!-- <div style="opacity: 0;height: 0;min-width: calc(100% + 16px);">{{getEntityName(props.row[schema.key])}}</div> -->
          <tw-entity-icon v-if="props.row[schema.key]" :popover="true" :entityName="getEntityId(props.row[schema.key])" />
        </template>
      </el-table-column>
      <el-table-column
        v-else-if="schema.dataType === 'TradingStatusIcon'"
        :key="schema.key"
        :prop="schema.key"
        :label="getLabel(schema.label)"
        :width="schema.width"
        :align="schema.align"
        :type="schema.dataType"
        :formatter="formatter"
        :fixed="schema.fixed"
        :render-header="renderHeader"
        :resizable="false"
      >
        <template slot-scope="props">
          <tw-trading-status-icon v-if="props.row[schema.key] || props.row[schema.key] === 0" :statusCode="props.row[schema.key]" />
        </template>
      </el-table-column>
      <el-table-column
        v-else-if="schema.dataType === 'AlertIcon'"
        :key="schema.key + 'AlertIcon'"
        :prop="schema.key"
        :label="getLabel(schema.label)"
        :width="schema.width"
        :align="schema.align"
        :type="schema.dataType"
        :formatter="formatter"
        :fixed="schema.fixed"
        :render-header="renderHeader"
        :resizable="false"
      >
        <template slot-scope="props">
          <tw-alert-icon :limit="props.row[schema.key]" :processProgressStatus="props.row['processProgressStatus'] || props.row['processProgressStatusCd']" type="line" />
        </template>
      </el-table-column>
      <el-table-column
        v-else-if="schema.dataType === 'Destination'"
        :key="schema.key"
        :prop="schema.key"
        :label="getLabel(schema.label)"
        :width="schema.width"
        :align="schema.align"
        :type="schema.dataType"
        :formatter="formatter"
        :fixed="schema.fixed"
        :render-header="renderHeader"
        :show-overflow-tooltip="!schema.hideTooltip"
        :resizable="false"
      >
        <template slot-scope="props">
          <div style="opacity: 0;height: 0;min-width: calc(100% + 16px);">{{getDestinations(props.row[schema.key])}}</div>
          <div class="avatars" style="padding-left: 16px">
            <figure v-for="(item, index) in props.row[schema.key].slice(0, 3)" :key="index" class="avatar table">
              <img :src="item.userIconTo">
            </figure>
            <div v-if="props.row[schema.key].length <= 1" class="customerName" style="margin-left: 8px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;}" :content="props.row[schema.key][0].userNameTo" />
            <!-- <div v-if="props.row[schema.key].length <= 1" class="customerName" style="display:flex;align-items:center" :content="getUserName(props.row, schema.key)"><figure class="avatar table"><img :src="getUserIcon(props.row, schema.key)"></figure></span> -->
          </div>
        </template>
      </el-table-column>
      <el-table-column
        v-else-if="schema.dataType === 'EntityList'"
        :key="schema.key"
        :prop="schema.key"
        :label="getLabel(schema.label)"
        :width="showProgressDetail ? 2245 : 336"
        :align="schema.align"
        :type="schema.dataType"
        :formatter="formatter"
        :fixed="schema.fixed"
        :resizable="false"
      >
        <!-- <template slot-scope="props"> -->
          <!-- <div class="avatars" style="padding-left: 16px">
            <figure v-for="(item, index) in props.row[schema.key].slice(0, 3)" :key="index" class="avatar table">
              <img :src="item.userIcon">
            </figure>
            <div v-if="props.row[schema.key].length <= 1">{{props.row[schema.key][0].userName}}</div>
          </div> -->
        <!-- </template> -->
        <template v-slot:header>
          <transition name="fade">
            <table v-if="!showProgressDetail" class="entity_statuses_header" @click="showProgressDetail = !showProgressDetail">
              <tr>
                <td v-for="entity in entityNames" :key="entity.entityId">
                  <tw-entity-icon :entityName="entity.entityId" :popover="true" size="24" style="margin: 0 auto;box-sizing:content-box;padding-right: 4px" />
                </td>
              </tr>
            </table>
          </transition>
          <transition name="fade">
            <table v-if="showProgressDetail" class="progress_statuses_header" @click="showProgressDetail = !showProgressDetail">
              <tr>
                <th colspan="2" class="contract"><div class="bar"/></th>
                <th colspan="1" class="lc"><div class="bar"/></th>
                <th colspan="1" class="transportation_agreement"><div class="bar"/></th>
                <th colspan="6" class="ex_customs"><div class="bar"/></th>
                <th colspan="5" class="transport"><div class="bar"/></th>
                <th colspan="4" class="marine_ip"><div class="bar"/></th>
                <th colspan="12" class="final_docs"><div class="bar"/></th>
                <th colspan="6" class="im_customs"><div class="bar"/></th>
              </tr>
              <tr>
                <td>Snd P/O</td>
                <td>COS/COP</td>
                <td>Str L/C</td>
                <td>Req BKG</td>
                <td>Ex. S/I 1</td>
                <td>Ex. S/I 2</td>
                <td>Ex. S/I 3</td>
                <td>Ex. S/I 4</td>
                <td>Ex. S/I 5</td>
                <td>Str ECP</td>
                <td>Req BKG</td>
                <td>Req B/L </td>
                <td>Str B/L</td>
                <td>B/L Inf 1</td>
                <td>B/L Inf 2</td>
                <td>Req I/P 1</td>
                <td>Req I/P 2</td>
                <td>Req I/P 3</td>
                <td>Str I/P</td>
                <td>Crt DOC</td>
                <td>Req C/O 1</td>
                <td>Iss C/O 1</td>
                <td>Req C/O 2</td>
                <td>Iss C/O 2</td>
                <td>Req C/O 3</td>
                <td>Iss C/O 3</td>
                <td>Req C/O 4</td>
                <td>Iss C/O 4</td>
                <td>Req C/O 5</td>
                <td>Iss C/O 5</td>
                <!-- <td>Iss C/O</td>
                <td>Iss C/O</td>
                <td>Iss C/O</td>
                <td>Iss C/O</td>
                <td>Iss C/O</td> -->
                <td>Snd DOC</td>
                <td>Im. Ins</td>
                <td>Im. Ins</td>
                <td>Im. Ins</td>
                <td>Im. Ins</td>
                <td>Im. Ins</td>
                <td>Str ICP</td>
              </tr>
            </table>
          </transition>
        </template>
        <template slot-scope="props">
          <transition name="fade">
            <table v-if="!showProgressDetail" class="entity_statuses_body">
              <tr style="background:none">
                <td v-for="entity in entityNames" :key="entity.entityId">
                  <tw-entity-status-icon v-if="getEntityProgressStatusCd(props.row[schema.key], entity.code) || getEntityProgressStatusCd(props.row[schema.key], entity.code) === 0" :statusCode="getEntityProgressStatusCd(props.row[schema.key], entity.code)" />
                  <span v-else />
                </td>
              </tr>
            </table>
          </transition>
          <transition name="fade">
            <table v-if="showProgressDetail" class="progress_statuses_body">
              <tr style="background:none">
                <td v-for="(item, index) in flatProgressStatus(props.row[schema.key])" :key="item.processId + index">
                  <tw-status-icon v-if="item.processProgressStatusCd || item.processProgressStatusCd === 0" :statusCode="item.processProgressStatusCd" :approval="getProgressApproval(item.processId)" />
                  <span v-else />
                </td>
              </tr>
            </table>
          </transition>
        </template>
      </el-table-column>
      <el-table-column
        v-else-if="schema.dataType === 'FlowDetail'"
        :key="schema.key"
        :prop="schema.key"
        :label="getLabel(schema.label)"
        :width="schema.width"
        :align="schema.align"
        :type="schema.dataType"
        :formatter="formatter"
        :fixed="schema.fixed"
        :render-header="renderHeader"
        class-name="trading_detail_link"
        :show-overflow-tooltip="!schema.hideTooltip"
      >
        <template slot-scope="props">
          <span v-if="props.row.flowDetailViewableFlg !== DETAIL_VIEWABLE_FLG.OFF" style="color: #5185C5;cursor: pointer" @click="$emit('show-flow-detail', props.row)">{{props.row[schema.key]}}</span>
          <span v-else style="cursor: not-allowed">{{props.row[schema.key]}}</span>
        </template>
      </el-table-column>
      <el-table-column
        v-else-if="schema.dataType === 'FlowNameInput'"
        :key="schema.key"
        :prop="schema.key"
        :label="getLabel(schema.label)"
        :width="schema.width"
        :align="schema.align"
        :type="schema.dataType"
        :formatter="formatter"
        :fixed="schema.fixed"
        :render-header="renderHeader"
        class-name="no_padding input"
      >
        <template slot-scope="props">
          <el-form-item class="flow_name" :label="getLabel('Flow Name')" :prop="`items[${props.$index}].tradingManageName`" :rules="flowNameRule">
            <el-input v-model="props.row[schema.key]" size="mini" :disabled="props.row.editableFlg !== EDITABLE_FLG.ON || (mode === 'editFlow' && !props.row.currentFlow)" @input="$emit('dirty')" show-word-limit maxlength="100"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column
        v-else-if="schema.dataType === 'Delete'"
        :key="schema.key"
        :prop="'Delete'"
        :label="getLabel(schema.label)"
        :width="schema.width"
        :align="schema.align"
        :type="schema.dataType"
        :formatter="formatter"
        :fixed="schema.fixed"
        :render-header="renderHeader"
        :resizable="false"
      >
        <template slot-scope="props">
          <img v-if="props.row.deletableFlg === DELETE_ABLE_FLG.OK" class="close_times" src="@/assets/images/icons/times.svg">
        </template>
      </el-table-column>
      <el-table-column
        v-else-if="schema.dataType === 'DeleteGoods'"
        :key="schema.key"
        :prop="'Delete'"
        :label="getLabel(schema.label)"
        :width="schema.width"
        :align="schema.align"
        :type="schema.dataType"
        :formatter="formatter"
        :fixed="schema.fixed"
        :render-header="renderHeader"
        :resizable="false"
      >
        <template slot-scope="props">
          <img v-if="props.row.deletable" class="close_times hidden" src="@/assets/images/icons/times.svg">
        </template>
      </el-table-column>
      <el-table-column
        v-else-if="schema.dataType === 'ProcessExShortName'"
        :key="schema.key"
        :prop="schema.key"
        :label="getLabel(schema.label)"
        :width="schema.width"
        :align="schema.align"
        :type="schema.dataType"
        :formatter="formatter"
        :fixed="schema.fixed"
        :render-header="renderHeader"
        :resizable="false"
      >
        <template slot-scope="props">
          <router-link :to="getProcessPagePrams(props.row)" tabindex="-1">{{getProcessExShortName(props.row.processId)}}</router-link>
        </template>
      </el-table-column>
      <el-table-column
        v-else-if="schema.dataType === 'MailLink'"
        :key="schema.key"
        :prop="schema.key"
        :label="getLabel(schema.label)"
        :width="schema.width"
        :align="schema.align"
        :type="schema.dataType"
        :formatter="formatter"
        :fixed="schema.fixed"
        :render-header="renderHeader"
        :show-overflow-tooltip="!schema.hideTooltip"
        :resizable="false"
      >
        <template slot-scope="props">
          <router-link :to="getMailPagePrams(props.row)" tabindex="-1">{{props.row[schema.key]}}</router-link>
        </template>
      </el-table-column>
      <el-table-column
        v-else-if="schema.dataType === 'TradingFlowName'"
        :key="schema.key"
        :prop="schema.key"
        :label="getLabel(schema.label)"
        :width="schema.width"
        :align="schema.align"
        :type="schema.dataType"
        :formatter="formatter"
        :fixed="schema.fixed"
        :render-header="renderHeader"
        :show-overflow-tooltip="!schema.hideTooltip"
      >
        <template slot-scope="props">
          <router-link v-if="isShipper" :to="getTradingPagePrams(props.row)" tabindex="-1">{{`${props.row.tradingName}（${props.row.tradingManageName}）`}}</router-link>
          <span v-else>{{`${props.row.tradingName}（${props.row.tradingManageName}）`}}</span>
        </template>
      </el-table-column>
      <el-table-column
        v-else-if="schema.dataType === 'Catalog'"
        :key="schema.key"
        :prop="'Catalog'"
        :label="getLabel(schema.label)"
        :width="schema.width"
        :align="schema.align"
        :type="schema.dataType"
        :formatter="formatter"
        :fixed="schema.fixed"
        :render-header="renderHeader"
        :resizable="false"
      >
        <template slot-scope="props">
          <tw-drop-down-catalog v-if="props.row.files.length" :files="props.row.files" :goodsId="props.row.goodsId" @select-file="selectFile" />
        </template>
      </el-table-column>
      <el-table-column
        v-else-if="schema.dataType === 'Download'"
        :key="schema.key"
        :prop="schema.key"
        :label="getLabel(schema.label)"
        :width="schema.width"
        :align="schema.align"
        :type="schema.dataType"
        :formatter="formatter"
        :fixed="schema.fixed"
        :render-header="renderHeader"
        :show-overflow-tooltip="!schema.hideTooltip"
        :resizable="false"
      >
        <template slot-scope="props">
          <span class="download_link" @click.stop.prevent="selectFile(props.row)">{{props.row[schema.key]}}</span>
        </template>
      </el-table-column>
      <el-table-column
        v-else-if="schema.dataType === 'FlowMainSub'"
        :key="schema.key"
        :prop="schema.key"
        :label="getLabel(schema.label)"
        :width="schema.width"
        :align="schema.align"
        :type="schema.dataType"
        :formatter="formatter"
        :fixed="schema.fixed"
        :render-header="renderHeader"
        class-name="flow_border"
        :resizable="false"
      >
        <template slot-scope="props">
          <span :class="[getFlowLabel(props.row[schema.key])]">{{getFlowLabel(props.row[schema.key])}}</span>
        </template>
      </el-table-column>
      <el-table-column
        v-else-if="schema.dataType === 'AlertMessage'"
        :key="schema.key"
        :prop="schema.key"
        :label="getLabel(schema.label)"
        :width="schema.width"
        :align="schema.align"
        :type="schema.dataType"
        :formatter="formatter"
        :fixed="schema.fixed"
        :render-header="renderHeader"
        class-name="alert_message"
        :show-overflow-tooltip="!schema.hideTooltip"
      >
        <template slot-scope="props">
          <span v-html="sanitize(props.row[schema.key])" />
        </template>
      </el-table-column>
      <el-table-column
        v-else-if="schema.dataType === 'RequestedConfirmationPartyOption'"
        :key="schema.key"
        :prop="schema.key"
        :label="getLabel(schema.label)"
        :width="schema.width"
        :align="schema.align"
        :type="schema.dataType"
        :formatter="formatter"
        :fixed="schema.fixed"
        :render-header="renderHeader"
        :show-overflow-tooltip="!schema.hideTooltip"
      >
        <template slot-scope="props">
          <span>{{getRequestedConfirmationPartyOption(props.row)}}</span>
        </template>
      </el-table-column>
      <el-table-column
        v-else-if="schema.dataType === 'Published'"
        :key="schema.key"
        :prop="schema.key"
        :label="getLabel(schema.label)"
        :width="schema.width"
        :align="schema.align"
        :type="schema.dataType"
        :formatter="formatter"
        :fixed="schema.fixed"
        :render-header="renderHeader"
      >
        <template slot-scope="props">
          <span v-if="props.row[schema.key] === INFORMATIONS_PUBLISHED_FLG.DRAFT" class="draft_label">Draft</span>
        </template>
      </el-table-column>
      <el-table-column
        v-else-if="schema.dataType === 'NewsStatus'"
        :key="schema.key"
        :prop="schema.key"
        :label="getLabel(schema.label)"
        :width="schema.width"
        :align="schema.align"
        :type="schema.dataType"
        :formatter="formatter"
        :fixed="schema.fixed"
        :render-header="renderHeader"
        :show-overflow-tooltip="!schema.hideTooltip"
      >
        <template slot-scope="props">
          <div style="opacity: 0;height: 0;min-width: calc(100% + 16px);">{{getNewsStatus(props.row[schema.key])}}</div>
          <span class="importance_icon" :class="[`news_status_${props.row[schema.key]}`]" />
        </template>
      </el-table-column>
      <el-table-column
        v-else-if="schema.dataType === 'New'"
        :key="schema.key"
        :prop="schema.key"
        :label="getLabel(schema.label)"
        :width="schema.width"
        :align="schema.align"
        :type="schema.dataType"
        :formatter="formatter"
        :fixed="schema.fixed"
        :render-header="renderHeader"
      >
        <template slot-scope="props">
          <span v-if="props.row[schema.key]" class="new_icon">NEW</span>
        </template>
      </el-table-column>
      <el-table-column
        v-else-if="schema.dataType === 'NewsType'"
        :key="schema.key"
        :prop="schema.key"
        :label="getLabel(schema.label)"
        :width="schema.width"
        :align="schema.align"
        :type="schema.dataType"
        :formatter="formatter"
        :fixed="schema.fixed"
        :render-header="renderHeader"
        :show-overflow-tooltip="!schema.hideTooltip"
      >
        <template slot-scope="props">
          <template v-if="props.row.priorityFlg === PRIORITY_FLG.ON">
            <div style="opacity: 0;height: 0;min-width: calc(100% + 16px);">{{getNewsType(props.row[schema.key])}}</div>
            <span class="importance_icon" :class="[`news_type_${props.row[schema.key]}`]" />
          </template>
        </template>
      </el-table-column>
      <el-table-column
        v-else-if="schema.dataType === 'UpdateUser'"
        :key="schema.key"
        :prop="schema.key"
        :label="getLabel(schema.label)"
        :width="schema.width"
        :align="schema.align"
        :type="schema.dataType"
        :formatter="formatter"
        :fixed="schema.fixed"
        :render-header="renderHeader"
        :show-overflow-tooltip="!schema.hideTooltip"
      >
        <template slot-scope="props">
          <div style="opacity: 0;height: 0;min-width: calc(100% + 16px);">{{props.row.updateUserName}}</div>
          <span class="customerName" style="display:flex;align-items:center" :content="props.row.updateUserName"><figure class="avatar table"><img :src="props.row.updateUserIcon"></figure></span>
        </template>
      </el-table-column>
      <el-table-column
        v-else-if="schema.dataType === 'NewsEdit'"
        :key="schema.key"
        :prop="schema.key"
        :label="getLabel(schema.label)"
        :width="schema.width"
        :align="schema.align"
        :type="schema.dataType"
        :formatter="formatter"
        :fixed="schema.fixed"
        :render-header="renderHeader"
      >
        <template slot-scope="props">
          <figure class="attach_file cursor_pointer" @click="showNewsEdit(props.row.informationId)"><img src="@/assets/images/icons/icon_edit.svg"></figure>
        </template>
      </el-table-column>
      <el-table-column
        v-else-if="schema.dataType === 'EstimatedTerminalCutoff'"
        :key="schema.key"
        :prop="schema.key"
        :label="getLabel(schema.label)"
        :width="schema.width"
        :align="schema.align"
        :type="schema.dataType"
        :formatter="formatter"
        :fixed="schema.fixed"
        :render-header="renderHeader"
      >
        <template slot-scope="props">
          <div class="estimated_date">
            <span>{{props.row[schema.key]}}</span>
            <el-tooltip v-if="props.row[schema.key]" placement="top" popper-class="mcu" :tabindex="-1">
              <div slot="content">This is an estimated date (not a confirmed date).</div>
              <img src="@/assets/images/icons/icon_info_blue.svg">
            </el-tooltip>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        v-else-if="schema.dataType === 'PermitLink'"
        :key="schema.key"
        :prop="schema.key"
        :label="getLabel(schema.label)"
        :width="schema.width"
        :align="schema.align"
        :type="schema.dataType"
        :formatter="formatter"
        :fixed="schema.fixed"
        :render-header="renderHeader"
      >
        <template slot-scope="props">
          <span v-if="props.row[schema.key]"><figure class="attach_file"><img src="@/assets/images/icons/icon_chain.svg" style="width:20px"></figure></span>
        </template>
      </el-table-column>
      <el-table-column
        v-else-if="schema.dataType === 'Amendment'"
        :key="schema.key"
        :prop="schema.key"
        :label="getLabel(schema.label)"
        :width="schema.width"
        :align="schema.align"
        :type="schema.dataType"
        :formatter="formatter"
        :fixed="schema.fixed"
        :render-header="renderHeader"
      >
        <template slot-scope="props">
          <span v-if="props.row[schema.key]"><figure class="attach_file"><img src="@/assets/images/icons/icon_check.svg" style="width:24px"></figure></span>
        </template>
      </el-table-column>
      <el-table-column
        v-else-if="schema.dataType === 'JPYCurrency'"
        :key="schema.key"
        :prop="schema.key"
        :label="getLabel(schema.label)"
        :width="schema.width"
        :align="schema.align"
        :type="schema.dataType"
        :formatter="formatter"
        :fixed="schema.fixed"
        :render-header="renderHeader"
        :show-overflow-tooltip="!schema.hideTooltip"
      >
        <template #default='props'>
          <span v-if="$_.isNil(props.row[schema.key])">
            -
          </span>
          <span v-else>
            {{ formattingNumber(props.row[schema.key], 'ja-JP', { style: 'currency', currency: 'JPY' }) }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        v-else-if="schema.dataType === 'YearMonth'"
        :key="schema.key"
        :prop="schema.key"
        :label="getLabel(schema.label)"
        :width="schema.width"
        :align="schema.align"
        :type="schema.dataType"
        :formatter="formatter"
        :fixed="schema.fixed"
        :render-header="renderHeader"
        :show-overflow-tooltip="!schema.hideTooltip"
      >
        <template #default='props'>
          {{ dayjs(props.row[schema.key]).isValid()
          ? dayjs(props.row[schema.key]).format('YYYY-MM')
          : 'Invalid date' }}
        </template>
      </el-table-column>
      <el-table-column
        v-else-if="schema.dataType === 'OtherTrading'"
        :key="schema.key"
        :prop="schema.key"
        :label="getLabel(schema.label)"
        :width="schema.width"
        :align="schema.align"
        :type="schema.dataType"
        :formatter="formatter"
        :fixed="schema.fixed"
        :render-header="renderHeader"
        class-name="trading_detail_link"
        :show-overflow-tooltip="!schema.hideTooltip"
      >
        <!-- サブフローではない別取引に表示する -->
        <template v-if="props.row[schema.key] === OTHER_TRADING_FLG.ON && props.row.subordinateInfo.subFlg === 0" slot-scope="props">
          <img src="@/assets/images/icons/icon_external_link.svg" style="width:20px">
        </template>
      </el-table-column>
      <el-table-column
        v-else
        :key="schema.key"
        :prop="schema.key"
        :label="getLabel(schema.label)"
        :width="schema.width"
        :align="schema.align"
        :type="schema.dataType"
        :formatter="formatter"
        :fixed="schema.fixed"
        :render-header="renderHeader"
        :class-name="`${schema.bold ? 'bold ' : ''}${schema.dataType === 'LinkColor' || schema.dataType === 'ProcessLinkColor' ? 'link ' : ''}${schema.key === 'tradingChannelInfo.tradingChannelNo' ? 'tc_no ' : ''}${schema.hideTooltip ? ' hide_tooltip' : ''}`"
        :show-overflow-tooltip='!schema.hideTooltip'
      />
    </template>

    <!-- ActionsのButtonを追加する場合-->
    <el-table-column
      v-if='actions'
      fixed='right'
      :label="actions.length === 1 ? actions[0].label : 'Actions'"
      align='center'
      :formatter='formatter'
      class='actions'
    >
      <template #default='props'>
        <el-button v-for='(action, index) in actions' :key='index' @click.stop='onActionClick($event, action, props.row)'
                   :icon='action.icon'
                   size='small'
                   :disabled='typeof action.isDisabled === "function" ? action.isDisabled(props.row) : false'
        >
          <span v-if="typeof action.icon === 'undefined'">
            {{ action.label }}
          </span>
        </el-button>
      </template>
    </el-table-column>

    <!-- Actionsのドロップダウンメニューを追加する場合-->
    <el-table-column
      v-if='actionsMenu'
      fixed='right'
      label='Actions'
      align='center'
      :width="120"
      :formatter='formatter'
    >
      <template #default='props'>
        <el-dropdown @command="onActionMenuClick($event, props.row)">
          <span class="el-dropdown-link">
            <img src='@/assets/images/icons/menu_horizontal.svg' alt=''>
          </span>

          <el-dropdown-menu slot="dropdown">
            <template v-for='action in actionsMenu'>
              <el-dropdown-item
                :command='action' class='actions_menu'
                :disabled='typeof action.isDisabled === "function" ? action.isDisabled(props.row) : false'
              >
                  <span v-if="action.icon === 'icon_edit'" class='actions_menu_icon'>
                    <img src='@/assets/images/icons/icon_edit.svg' width='16px' height='17px' alt='' />
                  </span>

                  <span v-if="action.icon === 'calendar_expire'" class='actions_menu_icon'>
                    <img src='@/assets/images/icons/calendar_expire.svg' width='14px' height='16px' alt='' />
                  </span>

                  <span v-if="action.icon === 'circle_minus'" class='actions_menu_icon'>
                    <img src='@/assets/images/icons/circle_minus.svg' width='16px' height='17px' alt='' />
                  </span>

                  {{ action.label }}
              </el-dropdown-item>
            </template>
          </el-dropdown-menu>
        </el-dropdown>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import mixinTable from '@/utils/mixinTable.js';
import TwProcessTable from '@/components/molecules/TwProcessTable';
import dayjs from 'dayjs';
import VueRouter from 'vue-router';
import { formattingNumber } from '../../utils/formattingUtils';

export default {
  name: 'TwTable',
  mixins: [mixinTable],
  components: {
    TwProcessTable,
  },
  props: {
    editableTradingFlowIds: Array,
    expandRowKeys: Array,
  },
  data() {
    return{
      formattingNumber,
      dayjs,
      internalExpandRowKeys: this.$route?.query?.expand?.split(',') || undefined
    };
  },
  methods: {
    cellClick(row, column, cell, event) {
      if (this.mode === 'editFlow' && !this.editableTradingFlowIds.includes(row.tradingFlowId)) {
        event.preventDefault();
        event.stopPropagation();
        return;
      }
      if (column.property === 'Delete' && event.target.tagName.toLowerCase() === 'img') {
        event.preventDefault();
        event.stopPropagation();
        this.$emit('remove-flow', row);
      } else if (column.type === 'LinkColor' || column.type === 'ProcessLinkColor') {
        event.preventDefault();
        event.stopPropagation();
        this.$emit('link-click', row)
      } else if (column.type === 'OtherTrading' && event.target.tagName.toLowerCase() === 'img') {
        event.preventDefault();
        event.stopPropagation();
        this.$emit('other-trading-flow-click', row)
      }
    },
    onActionClick(event, action, row) {
      if (typeof action.onClick === 'function') {
        action.onClick(event, row);
      }
    },
    cellMouseEnter(row, column, cell, event) {
      this.$emit('cell-mouse-enter', row, column, cell, event);
    },
    cellMouseLeave(row, column, cell, event) {
      this.$emit('cell-mouse-leave', row, column, cell, event);
    },
    onActionMenuClick(event, row) {
      if (typeof event.onClick === 'function') {
        event.onClick(event, row);
      }
    },
    expandChange(row, expandedRows) {
      const ids = expandedRows.map(r => r.otherTradingId ? r.otherTradingId + '_' + r.tradingFlowId : r.tradingFlowId).join(',');
      // 開いている tradingFlowId をカンマ繋ぎの文字列にして、クエリパラメータに入れ込む
      const { isNavigationFailure, NavigationFailureType } = VueRouter;
      this.$router.replace({ path: `${this.$route.path}?expand=${ids}` }).catch(failure => {
        if (isNavigationFailure(failure, NavigationFailureType.cancelled) || isNavigationFailure(failure, NavigationFailureType.duplicated)) {
          // expand を連打した時に発生する エラーを握りつぶす
          // 挙動としては、連打の最後の状態が保存される
        }
      });
      row.isOpen = !row.isOpen;
      const rowKey = typeof this.rowKey === 'function' ? this.rowKey(row) : row[this.rowKey];
      if(this.internalExpandRowKeys !== undefined){
        const index = this.internalExpandRowKeys.indexOf(rowKey);
        if (index === -1) {
          this.internalExpandRowKeys.push(rowKey);
        } else if (index !== -1){
          this.internalExpandRowKeys.splice(index, 1);
        }
      } else if(this.internalExpandRowKeys === undefined){
        this.internalExpandRowKeys = [rowKey];
      }
    },
    expandAll(val) {
      const table = this.$refs.table;
      const data = table.data;
      for (let key in data) {
        if (data[key].isOpen !== val) table.toggleRowExpansion(data[key]);
      }
    },
    setExpand() {
      const table = this.$refs.table;
      const data = table.data;
      for (let key in data) {
        table.toggleRowExpansion(data[key], data[key].isOpen);
      }
    },
    clearSelection() {
      this.$refs.table.clearSelection();
    },
    toggleRowSelection(row, selected) {
      this.$refs.table.toggleRowSelection(row, selected);
    },
    // eslint-disable-next-line no-unused-vars
    headerDragend(newWidth, oldWidth, column, event) {
      // console.log(newWidth, oldWidth, column, event)
    },
    showNewsEdit(informationId) {
      this.$emit('news-edit-click', informationId);
    },
  },
  watch: {
    expandRowKeys(newKeys) {
      this.internalExpandRowKeys = newKeys;
    },
    mode(newMode) {
      if (newMode === 'edit' || newMode === 'editFlow') {
        this.$emit('expand-key-state', this.internalExpandRowKeys);
      }
    }
  },
  mounted(){
    if(this.internalExpandRowKeys === undefined && this.expandRowKeys !== undefined){
      this.internalExpandRowKeys = this.expandRowKeys;
    }
  }
  // watch: {
  //   items(val) {
  //     if (this.expand) {
  //       this.$nextTick(() => {
  //         this.setExpand();
  //       });
  //     }
  //   },
  // },
};
</script>

<style lang='scss' scoped>
// Actions Dropdown Menu
.actions_menu {
  display: flex;
  align-items: center;
  gap: 8px;

  .actions_menu_icon {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 20px;
  }
}

.actions_menu.is-disabled .actions_menu_icon {
  opacity: .5;
}
</style>

<style lang="scss">
.edit, .new {
  .tw_table {
    td .cell {
      cursor: default;
    }
    // td.input .cell {
    //   cursor: pointer;
    // }
  }
}

.edit_flow {
  .tw_table:not(.process_table)>div>table>tbody>tr:not(.current_flow) {
    >td>.cell>img.close_times {
      cursor: not-allowed;
    }
  }

  .tw_table {
    td .cell {
      cursor: default;
    }
    tr:not(.current-row):not(.current_flow) {
      td:not(.el-table__expanded-cell) {
        background-color: $color_gray_300;
      }

      td.input .cell {
        // cursor: default;
        background-color: transparent;
        .dummy_input.readonly {
          cursor: not-allowed;
        }
      }

      .tw_table.process_table.gray {
        cursor: not-allowed;

        tr, tr:hover {
          td, th, td .dummy_input {
            background-color: $color_gray_300;
          }
        }
      }

      .tw_table.process_table:not(.gray) {
        cursor: default;

        tr, tr:hover {
          td, th, td .dummy_input {
            background-color: transparent;
          }
        }
      }
    }
  }
}

.tw_table {
  @include card_shadow;
  border-radius: 8px;
}

.tw_table tr.hidden_flow {
  display: none;
}

.tw_table th {
  padding: 7px 0;
  height: 50px;
  // cursor: pointer;

  .cell {
    padding: 0 8px;
    max-height: 34px;
  }

  .cell > div {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    word-break: break-word;
    color: $color_gray_800;
  }
}

.tw_table {
  &.el-table--border th, &.el-table--border td {
    border-right: 0;//px solid transparent;
  }

  // &.el-table--border th {
  //   border-right: 1px solid $color_gray_400;
  // }

  // &.el-table--border th.normal {
  //   border-right: 1px solid transparent;
  // }
}

.tw_table {
  td {
    .cell {
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      white-space: nowrap;
      padding: 0 8px;
      cursor: pointer;

      .tw_entity_icon {
        display: block;
      }

      // .importance_icon {
      //   margin: 0 auto;
      // }

      .customerName:after {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        content: attr(content);
      }

      .customerName.masked:after {
        padding: 2px 10px;
        background: #e8e8ed;
        border-radius: 4px;
        font-size: 90%;
      }

      > a {
        color: $color_dark_blue;
        text-decoration: none;
      }
    }

    &.hide_tooltip {

      .cell {
        white-space: normal!important;
        overflow-wrap: anywhere;
      }
    }
  }
}

.tw_table td .cell

.tw_table td.bold .cell {
  font-weight: bold;
  color: $color_gray_800;
}

.tw_table td.link .cell {
  color: $color_dark_blue;
}

.tw_table td.tc_no .el-tooltip {
  width: 100%!important;
}

.tw_table .el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: $color_gray_100;
}

.tw_table .caret-wrapper {
  // visibility: hidden;
  display: none;
}

.tw_table th.is-leaf {
  border-bottom: 2px solid $color_gray_400;
}

.tw_table th.left_line, .tw_table td.left_line {
  border-left: 1px solid $color_gray_400;
}

.tw_table td {
  border-bottom: 1px solid $color_gray_300;

  img.close_times {
    width: 20px;
    height: 20px;
    display: block;
    cursor: pointer;

    &.hidden {
      opacity: 0;
    }
  }

  img.icon_file {
    display: block;
    width: 24px;
    height: 24px;
    margin: 0 auto;
  }

  .importance {
    font-size: 14px;
    color: $color_warning;
    background: url(../../assets/images/icons/icon_alert_danger.svg) no-repeat 0 center;
    background-size: 24px auto;
    padding-left: 32px;
  }

  .estimated_date {
    display: flex;
    align-items: center;
    > span {
      margin-right: 4px;
    }
    > img {
      margin-left: auto;
      width: 18px;
      height: 18px;
    }
  }
}

.tw_table tr:hover {
  td {
    img.close_times.hidden {
      opacity: 1;
    }
  }
}

.tw_table tr td:first-child > div.cell {
  padding-left: 20px;
}

.tw_table tr td.el-table-column--selection div.cell {
  padding-left: 8px;
}

.validation .tw_table tr.invalid td {
  background-color: rgba($color_warning, .1)!important;
}

.validation .tw_table tr.invalid.current-row td {
  background-color: #eddae0!important;
}

// .tw_table th.normal {
//   cursor: default;
// }

.tw_table th.sort {
  background-color: $color_gray_100;

  .caret-wrapper {
    width: 14px;
    display: inline-flex;
  }

  .sort-caret.ascending.active {
    border-bottom-color: $color_dark_blue;
  }
  .sort-caret.descending.active {
    border-top-color: $color_dark_blue;
  }
}

.tw_table .el-table__expanded-cell[class*='cell'] {
  padding: 0;
  background: $color_white;
}

.el-table__expanded-cell .lc_pool_detail {
  padding: 10px 20px 10px 48px;
}

.tw_table td.no_padding {
  padding: 0;
}

.tw_table td.input .cell {
  overflow: visible;

  .el-form-item__error {
    position: relative;
    top: 0;
    z-index: 2;
  }
}

.tw_table td.flow_border {
  padding: 0;

  .cell {
    padding: 0;


    span {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 13px;
      height: 44px;
      text-align: center;
      font-weight: bold;
      font-size: 12px;
      line-height: 20px;
      color: white;
    }

    span.M {
      background: #8AC7FF;
    }

    span.S {
      background: #DCEEFF;
      color: #70B7F8;
      margin-left: 13px;
    }
  }
}

.tw_table td .dummy_input {
  display: flex;
  align-items: center;
  width: 304px;
  height: 32px;
  background: $color_gray_100;
  border: 1px solid #ededf4;
  border-radius: 6px;
  font-size: 12px;
  line-height: 20px;
  color: $color_gray_800;
  padding: 0 8px;
  cursor: pointer;

  &.readonly {
    background: none;
    border: 1px solid rgba(0,0,0,0);
    cursor: default;
  }

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.tw_table td .download_link {
  color: $color_dark_blue;
  cursor: pointer;
}
// 添付ファイルアイコン
.tw_table td .attach_file {
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: auto;
  &.cursor_pointer {
    cursor: pointer;
  }
}

// お知らせ一覧 Newアイコン
.tw_table td .new_icon {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 32px;
  height: 18px;
  color: $color_white;
  font-weight: bold;
  font-size: 10px;
  line-height: 1px;
  padding: 4px 2px;
  background: $color_primary_blue;
  border-radius: 2px;
}

.validation .tw_table td .dummy_input {
  &.invalid {
    border-color: $color_warning;
  }

  &.invalid.from:after {
    display: block;
    color: $color_warning;
    content: 'Fromは入力必須です';
  }

  &.invalid.to:after {
    display: block;
    color: $color_warning;
    content: 'Toは入力必須です';
  }
}

table.progress_statuses_header,
table.progress_statuses_body {
  width: 100%;
  height: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
  opacity: 1;
  top: 0;
  left: 0;

  &.fade-enter-active {
    transition: all .3s linear;
  }

  &.fade-leave-active {
    transition: all 0s;
  }

  &.fade-leave-active {
    position: absolute;
  }

  &.fade-enter,
  &.fade-leave-to {
    opacity: 0;
  }

  th,
  td {
    padding: 0 8px;
    text-align: center;
    border: 0;
    font-size: 12px;
    line-height: 18px;
    color: $color_gray_800;
    white-space: nowrap;
    font-weight: normal;
  }
}

table.entity_statuses_header,
table.entity_statuses_body {
  width: 100%;
  height: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
  opacity: 1;
  top: 0;
  left: 0;

  &.fade-enter-active {
    transition: all .3s linear;
  }

  &.fade-leave-active {
    transition: all 0s;
  }

  &.fade-leave-active {
    position: absolute;
  }

  &.fade-enter,
  &.fade-leave-to {
    opacity: 0;
  }

  th,
  td {
    padding: 0 12px;
    text-align: center;
    border: 0;
    font-size: 12px;
    line-height: 18px;
    color: $color_gray_800;
    white-space: nowrap;
    font-weight: normal;
  }

  td {
    padding: 0 4px 0 8px;
  }
}

table.entity_statuses_header,
table.progress_statuses_header {
  td, th {
    cursor: pointer;
  }
}

.tw_table.process_table {
  box-shadow: none;
  border-radius: 0;
  border: 0;
  width: 100%;

  .el-table__body-wrapper.is-scrolling-none {
    overflow-y: hidden;
  }

  thead tr:first-child {
    position: relative;

    &:after {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.12);
      content: '';
    }
  }

  th {
    background: none;
    height: auto;
  }

  th.line {
    overflow: hidden;
    background: url(../../assets/images/line.svg) no-repeat 24px -10px;
    background-size: 32px auto;
  }

  th:not(.line) {
    background: #f9f9fd;
  }

  td.blank, th.blank {
    width: 0;
    max-width: 0;

    .cell {
      padding: 0;
    }
  }

  td.line {
    position: relative;
    height: 50px;

    .cell div {
      position: absolute;
      width: 100%;
      height: 150%;
      left: 0;
      top: 0;
      background: url(../../assets/images/line_straight.svg) repeat-y 24px;
      background-size: auto;
      transform: translateY(-55%);

      &.curve {
        background: url(../../assets/images/line.svg) no-repeat 24px bottom;
        background-size: 32px auto;
        height: 150%;
        transform: translateY(-62%);
      }

      &.no_line {
        display: none;
      }
    }
  }

  td.no div.cell {
    min-width: 290px;
  }

  tr td div.cell {
    padding-left: 8px;
  }

  tr td.preview_customer div.cell, tr td.select_customer div.cell {
    min-width: 320px;
  }

  tr:last-child td.line .cell div:not(.curve) {
    display: none;
  }

  tr.disabled * {
    cursor: not-allowed;
  }
}

table.progress_statuses_header {
  td {
    height: 20px;
    vertical-align: middle;
    padding: 2px 8px 0;
  }
  th {
    height: 4px;
    padding: 0 8px;
    font-weight: normal;

    .bar {
      height: 100%;
      border-radius: 2px;
    }

    &.contract .bar {
      @include gradation_sales_contract;
    }
    &.lc .bar {
      @include gradation_lc;
    }
    &.transportation_agreement .bar {
      height: 4px;
      @include gradation_transportation_agreement;
    }
    &.ex_customs .bar {
      height: 4px;
      @include gradation_ex_customs_clearance;
    }
    &.transport .bar {
      height: 4px;
      @include gradation_transport;
    }
    &.marine_ip .bar {
      height: 4px;
      @include gradation_insurance;
    }
    &.final_docs .bar {
      height: 4px;
      @include gradation_payment;
    }
    &.im_customs .bar {
      height: 4px;
      @include gradation_import_customs;
    }
  }
}


// プール一覧アラート用
.pool_list .tw_table tr.pool_alert td {
  background-color: rgba($color_warning, .1)!important;
}

.pool_list .tw_table tr.pool_alert.current-row td {
  background-color: #eddae0!important;
}

.pool_list .tw_table tr.pool_not_latest td {
  background-color: $color_gray_400_skeleton!important;
  color: $color_gray_600;
}

.pool_list .tw_table tr.pool_alert td.alert_message {
  color: $color_warning;
}

.el-button--text.operation-icon-wrapper {
  padding-left: 20px;
  padding-right: 20px;
}
.el-table__row.pool_selected {
  pointer-events: none;
  background-color: $color_gray_100;
  td .cell {
    opacity: 0.7;
  }
  .el-table-column--selection {
    .el-checkbox {
      display: none;
    }
  }
}

tr.other_trading {
  cursor: not-allowed;

  td, &:hover td {
    background-color: $color_gray_300!important;
  }

  td:not(.el-table__expand-column):not(.trading_detail_link) * {
    pointer-events: none;
  }

  td.input .cell {
    cursor: not-allowed;
    background-color: transparent;
  }

  & + tr td.el-table__expanded-cell {
    background-color: $color_gray_300!important;
  }

  & + tr .tw_table.process_table.gray {
    cursor: not-allowed;

    * {
      pointer-events: none;
    }

    tr, tr:hover {
      td, th, td .dummy_input {
        background-color: $color_gray_300!important;
      }
    }
  }
}
</style>
